import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  id: "kt_create_account_stepper",
  ref: "horizontalWizardRef",
  class: "stepper stepper-links"
}
const _hoisted_4 = { class: "stepper-nav row" }
const _hoisted_5 = {
  class: "stepper-item current col first",
  "data-kt-stepper-element": "nav"
}
const _hoisted_6 = { class: "stepper-title" }
const _hoisted_7 = {
  class: "stepper-item col",
  "data-kt-stepper-element": "nav"
}
const _hoisted_8 = { class: "stepper-title" }
const _hoisted_9 = {
  class: "stepper-item col",
  "data-kt-stepper-element": "nav"
}
const _hoisted_10 = { class: "stepper-title" }
const _hoisted_11 = {
  class: "stepper-item col last",
  "data-kt-stepper-element": "nav"
}
const _hoisted_12 = { class: "stepper-title" }
const _hoisted_13 = {
  class: "current",
  "data-kt-stepper-element": "content"
}
const _hoisted_14 = { class: "w-100" }
const _hoisted_15 = { class: "fv-row mb-10" }
const _hoisted_16 = { class: "form-label required" }
const _hoisted_17 = { class: "fv-row mb-10" }
const _hoisted_18 = { class: "form-label required" }
const _hoisted_19 = { class: "row mb-10" }
const _hoisted_20 = { class: "col mb-10" }
const _hoisted_21 = { class: "form-label required" }
const _hoisted_22 = { class: "col mb-10" }
const _hoisted_23 = { class: "form-label required" }
const _hoisted_24 = { class: "fv-row" }
const _hoisted_25 = { class: "form-label required" }
const _hoisted_26 = { "data-kt-stepper-element": "content" }
const _hoisted_27 = { class: "d-block w-100" }
const _hoisted_28 = { class: "row" }
const _hoisted_29 = { class: "col-12" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = {
  key: 0,
  class: "card bg-success bg-opacity-25 h-100 mt-3"
}
const _hoisted_32 = { class: "card-body d-flex flex-column pb-8" }
const _hoisted_33 = { class: "mb-3" }
const _hoisted_34 = ["innerHTML"]
const _hoisted_35 = {
  class: "fs-4 fw-bold text-hover-primary text-gray-900 m-0",
  href: "#"
}
const _hoisted_36 = {
  key: 1,
  class: "card bg-info bg-opacity-25 h-100 mt-3"
}
const _hoisted_37 = { class: "card-body d-flex flex-column pb-8" }
const _hoisted_38 = { class: "mb-3" }
const _hoisted_39 = ["innerHTML"]
const _hoisted_40 = {
  class: "fs-4 fw-bold text-hover-primary text-gray-900 m-0",
  href: "#"
}
const _hoisted_41 = {
  id: "add_system_question",
  class: "modal fade",
  tabindex: "-1"
}
const _hoisted_42 = { class: "modal-dialog" }
const _hoisted_43 = { class: "modal-content" }
const _hoisted_44 = { class: "modal-header" }
const _hoisted_45 = { class: "modal-title" }
const _hoisted_46 = { class: "modal-body pt-0" }
const _hoisted_47 = { class: "p-5 mt-5 mb-5 bg-primary bg-opacity-25 rounded" }
const _hoisted_48 = { class: "row" }
const _hoisted_49 = { class: "col" }
const _hoisted_50 = { class: "form-control form-control-solid" }
const _hoisted_51 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_52 = { class: "col" }
const _hoisted_53 = {
  class: "btn btn-primary btn-light",
  type: "submit"
}
const _hoisted_54 = { class: "card-body d-flex flex-column pb-8" }
const _hoisted_55 = { class: "mb-3" }
const _hoisted_56 = {
  class: "fs-4 fw-bold text-hover-primary text-gray-900 m-0",
  href: "#"
}
const _hoisted_57 = { class: "fw-bold mt-3 w-100 text-end" }
const _hoisted_58 = ["onClick"]
const _hoisted_59 = {
  id: "add_custom_question",
  class: "modal fade",
  tabindex: "-1"
}
const _hoisted_60 = { class: "modal-dialog modal-panel-right" }
const _hoisted_61 = { class: "modal-content" }
const _hoisted_62 = { class: "modal-body" }
const _hoisted_63 = { class: "row" }
const _hoisted_64 = { class: "col question-column light-background" }
const _hoisted_65 = { class: "mb-4" }
const _hoisted_66 = { class: "form-label required" }
const _hoisted_67 = { class: "mb-4" }
const _hoisted_68 = { class: "form-label required" }
const _hoisted_69 = { class: "mb-4" }
const _hoisted_70 = { class: "form-label required" }
const _hoisted_71 = { class: "d-flex flex-stack pt-15" }
const _hoisted_72 = { class: "mr-2" }
const _hoisted_73 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_74 = { class: "svg-icon svg-icon-3 ms-2 me-0" }
const _hoisted_75 = {
  key: 1,
  class: "btn btn-lg btn-primary",
  type: "submit"
}
const _hoisted_76 = { class: "svg-icon svg-icon-4 ms-1 me-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h3", _hoisted_6, [
              _cache[13] || (_cache[13] = _createElementVNode("span", { class: "stepper-step" }, "1", -1)),
              _createTextVNode(_toDisplayString(_ctx.$t("pages.surveyadd.step1")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h3", _hoisted_8, [
              _cache[14] || (_cache[14] = _createElementVNode("span", { class: "stepper-step" }, "2", -1)),
              _createTextVNode(_toDisplayString(_ctx.$t("pages.surveyadd.step2")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("h3", _hoisted_10, [
              _cache[15] || (_cache[15] = _createElementVNode("span", { class: "stepper-step" }, "3", -1)),
              _createTextVNode(_toDisplayString(_ctx.$t("pages.surveyadd.step3")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("h3", _hoisted_12, [
              _cache[16] || (_cache[16] = _createElementVNode("span", { class: "stepper-step" }, "4", -1)),
              _createTextVNode(_toDisplayString(_ctx.$t("pages.surveyadd.step4")), 1)
            ])
          ])
        ]),
        _createElementVNode("form", {
          id: "kt_create_account_form",
          class: "mx-auto w-100 pt-15 pb-10",
          novalidate: "novalidate",
          onSubmit: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.handleStep && _ctx.handleStep(...args)))
        }, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("pages.surveyadd.surveylabel")), 1),
                _createVNode(_component_Field, {
                  id: "label",
                  modelValue: this.survey.label,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.survey.label) = $event)),
                  class: "form-control form-control-lg",
                  name: "label",
                  type: "text"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("pages.surveyadd.surveydescription")), 1),
                _createVNode(_component_Field, {
                  modelValue: this.survey.description,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.survey.description) = $event)),
                  as: "textarea",
                  class: "form-control form-control-lg",
                  name: "description"
                }, null, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t("pages.surveyadd.date_begin")), 1),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((this.survey.date_begin) = $event)),
                    class: "form-control form-control-lg",
                    type: "date"
                  }, null, 512), [
                    [_vModelText, this.survey.date_begin]
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("pages.surveyadd.date_end")), 1),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((this.survey.date_end) = $event)),
                    class: "form-control form-control-lg",
                    type: "date"
                  }, null, 512), [
                    [_vModelText, this.survey.date_end]
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("pages.surveyadd.languages")), 1),
                _createVNode(_component_el_select, {
                  modelValue: this.survey.languages,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.survey.languages) = $event)),
                  "allow-create": "",
                  class: "w-100",
                  "default-first-option": "",
                  filterable: "",
                  multiple: "",
                  placeholder: "Choose tags for your target"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "Français",
                      value: "fr"
                    }),
                    _createVNode(_component_el_option, {
                      label: "Anglais",
                      value: "anglais"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("div", _hoisted_29, [
                  _cache[17] || (_cache[17] = _createElementVNode("h3", null, "Créez votre questionnaire", -1)),
                  _cache[18] || (_cache[18] = _createElementVNode("p", { class: "pt-4" }, " Pour créer votre questionnaire, vous pouvez choisir des questions de la questiothèque (banque de questions organisées par thématique et validées par Obea, notre partenaire externe) ou ajouter une question personnalisée si aucune question de la questiothèque ne correspond à votre besoin. C’est à vous ! ", -1)),
                  _createElementVNode("button", {
                    class: "btn btn-primary me-3 w-25",
                    "data-bs-target": "#add_custom_question",
                    "data-bs-toggle": "modal",
                    onClick: _cache[5] || (_cache[5] = 
                                            (e) => {
                                                e.preventDefault();
                                            }
                                        )
                  }, _toDisplayString(_ctx.$t("pages.surveyadd.addcustomquestion")), 1),
                  _createElementVNode("button", {
                    class: "btn btn-primary-inverse w-25",
                    "data-bs-target": "#add_system_question",
                    "data-bs-toggle": "modal",
                    onClick: _cache[6] || (_cache[6] = 
                                            (e) => {
                                                e.preventDefault();
                                            }
                                        )
                  }, _toDisplayString(_ctx.$t("pages.surveyadd.addsystemquestion")), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_30, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.selectedQuestions, (question) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: question.question,
                    class: "col-12 mt-5"
                  }, [
                    (!question.is_custom)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                          _createElementVNode("div", _hoisted_32, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_33, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.thematic, (thematic) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: thematic.name,
                                    innerHTML: thematic.name
                                  }, null, 8, _hoisted_34))
                                }), 128))
                              ]),
                              _createElementVNode("div", null, [
                                _cache[19] || (_cache[19] = _createElementVNode("i", { class: "fas fa-lightbulb fs-3 me-3 text-gray-900" }, null, -1)),
                                _createElementVNode("a", _hoisted_35, _toDisplayString(question.question), 1),
                                _cache[20] || (_cache[20] = _createElementVNode("br", null, null, -1))
                              ]),
                              _createElementVNode("i", null, "Answers : " + _toDisplayString(question.answers), 1)
                            ])
                          ])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_36, [
                          _createElementVNode("div", _hoisted_37, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_38, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.thematic, (thematic) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: thematic.name,
                                    innerHTML: thematic.name
                                  }, null, 8, _hoisted_39))
                                }), 128))
                              ]),
                              _createElementVNode("div", null, [
                                _cache[21] || (_cache[21] = _createElementVNode("i", { class: "fas fa-lightbulb fs-3 me-3 text-gray-900" }, null, -1)),
                                _createElementVNode("a", _hoisted_40, _toDisplayString(question.question), 1),
                                _cache[22] || (_cache[22] = _createElementVNode("br", null, null, -1))
                              ]),
                              _createElementVNode("i", null, "Answers : " + _toDisplayString(question.answers), 1)
                            ])
                          ])
                        ]))
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _createElementVNode("div", _hoisted_43, [
                    _createElementVNode("div", _hoisted_44, [
                      _createElementVNode("h5", _hoisted_45, _toDisplayString(_ctx.$t("pages.surveyadd.addsystemquestion")), 1),
                      _cache[23] || (_cache[23] = _createElementVNode("div", {
                        "aria-label": "Close",
                        class: "btn btn-icon btn-sm btn-active-light-primary ms-2",
                        "data-bs-dismiss": "modal"
                      }, [
                        _createElementVNode("span", { class: "svg-icon svg-icon-2x" })
                      ], -1))
                    ]),
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("div", _hoisted_47, [
                        _createElementVNode("div", _hoisted_48, [
                          _createElementVNode("div", _hoisted_49, [
                            _createElementVNode("select", _hoisted_50, [
                              _createElementVNode("option", _hoisted_51, _toDisplayString(_ctx.$t("pages.surveyadd.thematics")), 1),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.thematics, (thematic) => {
                                return (_openBlock(), _createElementBlock("option", {
                                  key: thematic.name
                                }, _toDisplayString(thematic.name), 1))
                              }), 128))
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_52, [
                            _createElementVNode("button", _hoisted_53, _toDisplayString(_ctx.$t("general.search")), 1),
                            _cache[24] || (_cache[24] = _createElementVNode("button", {
                              class: "ms-3 btn btn-icon btn-light btn-active-light-primary",
                              type: "submit"
                            }, [
                              _createElementVNode("i", { class: "fas fa-redo fs-7 pt-1" })
                            ], -1))
                          ])
                        ])
                      ]),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.ecchoQuestions, (question) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: question.question,
                          class: "card bg-success bg-opacity-25 h-50 mt-3"
                        }, [
                          _createElementVNode("div", _hoisted_54, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_55, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(question.thematic, (thematic) => {
                                  return (_openBlock(), _createElementBlock("span", {
                                    key: thematic.name,
                                    class: "badge-light badge text-primary me-3"
                                  }, _toDisplayString(thematic.name), 1))
                                }), 128))
                              ]),
                              _createElementVNode("div", null, [
                                _cache[25] || (_cache[25] = _createElementVNode("i", { class: "fas fa-lightbulb fs-3 me-3 text-gray-900" }, null, -1)),
                                _createElementVNode("a", _hoisted_56, _toDisplayString(question.question), 1),
                                _cache[26] || (_cache[26] = _createElementVNode("br", null, null, -1))
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_57, [
                              _createElementVNode("a", {
                                class: "btn btn-success btn-sm btn-light btn-active-light-success",
                                onClick: 
                                                                (e) => {
                                                                    e.preventDefault();
                                                                    this.addToSelectedQuestion(question);
                                                                }
                                                            
                              }, _toDisplayString(_ctx.$t("pages.surveyadd.add")), 9, _hoisted_58)
                            ])
                          ])
                        ]))
                      }), 128))
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_59, [
                _createElementVNode("div", _hoisted_60, [
                  _createElementVNode("div", _hoisted_61, [
                    _createElementVNode("div", _hoisted_62, [
                      _createElementVNode("div", _hoisted_63, [
                        _createElementVNode("div", _hoisted_64, [
                          _createElementVNode("h2", null, _toDisplayString(_ctx.$t("pages.surveyadd.addcustomquestion")), 1),
                          _createElementVNode("div", _hoisted_65, [
                            _createElementVNode("label", _hoisted_66, _toDisplayString(_ctx.$t("pages.surveyadd.thematics")), 1),
                            _createVNode(_component_el_select, {
                              modelValue: this.question.thematics,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((this.question.thematics) = $event)),
                              "allow-create": "",
                              class: "w-100",
                              "default-first-option": "",
                              filterable: "",
                              multiple: "",
                              placeholder: ""
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_option, {
                                  label: "Environnement de travail",
                                  value: "evt"
                                }),
                                _createVNode(_component_el_option, {
                                  label: "Management",
                                  value: "mgt"
                                })
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_67, [
                            _createElementVNode("label", _hoisted_68, _toDisplayString(_ctx.$t("pages.surveyadd.question")), 1),
                            _createVNode(_component_el_input, {
                              id: "question",
                              modelValue: this.question.question,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((this.question.question) = $event)),
                              name: "label",
                              type: "text"
                            }, null, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_69, [
                            _createElementVNode("label", _hoisted_70, _toDisplayString(_ctx.$t("pages.surveyadd.type")), 1),
                            _createVNode(_component_el_select, {
                              modelValue: this.question.type,
                              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((this.question.type) = $event)),
                              "allow-create": "",
                              class: "w-100",
                              "default-first-option": "",
                              filterable: "",
                              placeholder: ""
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionType, (type) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: type.value,
                                    label: type.value,
                                    value: type.name
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ])
                        ]),
                        _cache[27] || (_cache[27] = _createElementVNode("div", { class: "col" }, null, -1)),
                        _cache[28] || (_cache[28] = _createElementVNode("div", { class: "col" }, null, -1))
                      ])
                    ]),
                    _cache[29] || (_cache[29] = _createElementVNode("div", { class: "modal-footer" }, [
                      _createElementVNode("div", {
                        "aria-label": "Close",
                        class: "btn btn-icon btn-sm btn-active-light-primary ms-2",
                        "data-bs-dismiss": "modal"
                      }, [
                        _createElementVNode("span", { class: "svg-icon svg-icon-2x" })
                      ])
                    ], -1))
                  ])
                ])
              ])
            ])
          ]),
          _cache[30] || (_cache[30] = _createStaticVNode("<div data-kt-stepper-element=\"content\"><div class=\"card bg-primary bg-opacity-25 h-100 mt-3\"><div class=\"card-body\"><div class=\"m-4\"> Télécharger la population cible de l’enquête Pulse : <button class=\"btn btn-primary btn-sm\">Upload</button></div><div class=\"m-4\">Vous pouvez enregistrer l’enquête et télécharger votre fichier avec les collaborateurs à interroger dans un second temps</div></div></div></div>", 1)),
          _createElementVNode("div", _hoisted_71, [
            _createElementVNode("div", _hoisted_72, [
              _createElementVNode("button", {
                class: "btn btn-lg btn-light-primary me-3",
                "data-kt-stepper-action": "previous",
                type: "button",
                onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.previousStep && _ctx.previousStep(...args)))
              }, [
                _createElementVNode("span", _hoisted_73, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr063.svg" })
                ]),
                _createTextVNode(" " + _toDisplayString(_ctx.$t("general.back")), 1)
              ])
            ]),
            _createElementVNode("div", null, [
              (_ctx.currentStepIndex === _ctx.totalSteps - 1)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn btn-lg btn-primary me-3",
                    type: "button",
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.formSubmit()))
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t("general.done")) + " ", 1),
                    _createElementVNode("span", _hoisted_74, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("button", _hoisted_75, [
                    _createTextVNode(_toDisplayString(_ctx.$t("general.next")) + " ", 1),
                    _createElementVNode("span", _hoisted_76, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                    ])
                  ]))
            ])
          ])
        ], 32)
      ], 512)
    ])
  ]))
}